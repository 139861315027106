import axios from "@axios";

export default {
  namespaced: true,
  state: {
    sites: [],
    audienceType: "ga",
    rwmData: {},
  },
  getters: {
    getSites: (state) => state.sites,
    getAudienceType: (state) => state.audienceType,
    getRwmData: (state) => state.rwmData,
  },
  mutations: {
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateAudienceType(state, payload) {
      state.audienceType = payload;
    },
    updateRwmData(state, payload) {
      state.rwmData = payload;
    },
  },
  actions: {
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    updateRwmData({ commit }, payload) {
      commit("updateRwmData", payload);
    },
    updateAudienceType({ commit }, payload) {
      commit("updateAudienceType", payload);
    },
    async getRwmData({ commit }, payload) {
      const response = await axios.get("/audience/rwm-data", {
        params: payload,
      });
      commit("updateRwmData", response.data);
    }
  },
};
