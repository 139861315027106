import generalNav from "./general";
import ctcNav from "./ctc";
import programmatiqueNav from "./programmatique";
import arbitrageNav from "./arbitrage";
import seoNav from "./seo";
import crmNav from "./crm";
import audienceNav from "./audience";
import regieNav from "./regie";
import marmitonNav from "./marmiton";
import adminNav from "./admin";
import financeNav from "./finance";
import googleDiscoverNav from "./googleDiscover";
import rmrnAnalyticsNav from "./rmrAnalytics";

export default [
  ...generalNav,
  ...ctcNav,
  ...programmatiqueNav,
  ...arbitrageNav,
  ...seoNav,
  ...crmNav,
  ...audienceNav,
  ...regieNav,
  ...marmitonNav,
  ...rmrnAnalyticsNav,
  ...googleDiscoverNav,
  ...financeNav,
  ...adminNav,
];
