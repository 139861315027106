import axios from "@axios";

export default {
  namespaced: true,
  state: {
    dates: [],
    sites: [],
    siteGroups: [],
  },
  getters: {
    getDates: (state) => {
      // always return the dates ordered
      return state.dates[0] < state.dates[1]
        ? [state.dates[0], state.dates[1]]
        : [state.dates[1], state.dates[0]];
    },
    getSites: (state) => {
      return state.sites;
    },
    getSiteGroups: (state) => {
      return state.siteGroups;
    },
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateDates", payload);
      }
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const site_ids = data
        .map((x) => x["site_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });

      commit("updateSites", site_ids);

      return site_ids;
    },
  },
};
