const audienceRoutes = [
  /* {
    path: "/audience/home",
    name: "audience-home",
    component: () => import("@/views/audience/Index.vue"),
    meta: {
      layout: "content",
      resource: "audience",
      action: "manage",
    },
  }, */
  {
    path: "/audience/by-source",
    name: "audience-by-source",
    component: () => import("@/views/audience/AudienceBySource.vue"),
    meta: {
      layout: "content",
      resource: "audience",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-by-source",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/audience/perf-per-article",
    name: "audience-perf-article",
    component: () => import("@/views/audience/PerfPerArticle.vue"),
    props: {
      department: "audience",
    },
    meta: {
      layout: "content",
      resource: "audience",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-perf-per-article",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/audience/perf-per-category",
    name: "audience-perf-category",
    component: () => import("@/views/audience/PerfPerCategory.vue"),
    meta: {
      layout: "content",
      resource: "audience",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-perf-per-category",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/audience/redac-setups",
    name: "redac-setups",
    component: () => import("@/views/audience/RedacSetup.vue"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-redac-setups",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/audience/renta-articles",
    name: "renta-articles",
    component: () => import("@/views/audience/RentaArticles.vue"),
    meta: {
      layout: "content",
      resource: "admin",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-renta-articles",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/audience/universal-analytics/site-sessions-views",
    name: "audience-ua-site-sessions-views",
    component: () =>
      import("@/views/audience/universal-analytics/SiteSessionsViews.vue"),
    meta: {
      layout: "content",
      resource: "audience",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-ua-site-sessions-views",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/audience/universal-analytics/data",
    name: "audience-ua-table-data",
    component: () =>
      import("@/views/audience/universal-analytics/MonthlyDetailed.vue"),
    meta: {
      layout: "content",
      resource: "audience",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-ua-table-data",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/audience/universal-analytics/month-counts",
    name: "audience-ua-global-month-counts",
    component: () =>
      import("@/views/audience/universal-analytics/MonthlyCounts.vue"),
    meta: {
      layout: "content",
      resource: "audience",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-ua-global-month-counts",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/audience/acpm",
    name: "audience-acpm",
    component: () => import("@/views/audience/Acpm.vue"),
    meta: {
      layout: "content",
      resource: "audience",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "audience-acpm",
        eventCategory: "page-view",
      },
    },
  },
];

export default audienceRoutes;
