const crmRoutes = [
  {
    path: "/ga-sites-by-filter/crm",
    name: "ga-sites-by-filter-crm",
    component: () => import("@/views/pages/GaSitesByFilter.vue"),
    props: {
      departmentShortnameSelected: "crm",
    },
    meta: {
      layout: "content",
      resource: "crm",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "crm-ga-site-filter",
        eventCategory: "page-view",
      },
    },
  },
];

export default crmRoutes;
