import { mdiCurrencyUsd } from "@mdi/js";

const programmatiqueNav = [
  {
    title: "Programmatique",
    icon: mdiCurrencyUsd,
    children: [
      {
        title: "Dashboard",
        to: "premium-general",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "Daily CA",
        to: "premium-gss",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "CA / partenaire",
        to: "premium-ca-by-partner-view",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "CA / site",
        to: "premium-ca-and-audience-by-site",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "Inventaire",
        to: "premium-inventaire",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "Liste des objectifs",
        to: "premium-objectives-list",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "Edition d'objectifs",
        to: "premium-objectives-edit",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "Sites Matching",
        to: "premium-sites-matching",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "Sites matchés",
        to: "premium-sites-matched-list",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "M.G. Pepsia",
        to: "premium-pepsia-mg-manage",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "GAM Prebid by pubstack",
        to: "premium-gam-prebid-pubstack",
        resource: "programmatique",
        action: "manage",
      },
      {
        title: "Stats mensuelles",
        to: "premium-achievement-report",
        resource: "programmatique",
        action: "manage",
      },
    ],
  },
];

export default programmatiqueNav;
