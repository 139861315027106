import axios from "@axios";

export default {
  namespaced: true,
  state: {
    dates: [],
    eventTypes: [],
    events: [],
    projects: [],
    eventCategories: [],
    filteredEvents: [],
    eventTypesList: [],
    projectsList: [],
    eventCategoriesList: [],
  },
  getters: {
    getDates: (state) => {
      return state.dates;
    },
    getEventTypes: (state) => {
      return state.eventTypes;
    },
    getEvents: (state) => {
      return state.events;
    },
    getProjects: (state) => {
      return state.projects;
    },
    getEventCategories: (state) => {
      return state.eventCategories;
    },
    getFilteredEvents: (state) => {
      return state.filteredEvents;
    },
    getEventTypesList: (state) => {
      return state.eventTypesList;
    },
    getProjectsList: (state) => {
      return state.projectsList;
    },
    getEventCategoriesList: (state) => {
      return state.eventCategoriesList;
    },
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateEventTypes(state, payload) {
      state.eventTypes = payload;
    },
    updateEvents(state, payload) {
      state.events = payload;
    },
    updateProjects(state, payload) {
      state.projects = payload;
    },
    updateEventCategories(state, payload) {
      state.eventCategories = payload;
    },
    updateFilteredEvents(state, payload) {
      state.filteredEvents = payload;
    },
    updateEventTypesList(state, payload) {
      state.eventTypesList = payload;
    },
    updateProjectsList(state, payload) {
      state.projectsList = payload;
    },
    updateEventCategoriesList(state, payload) {
      state.eventCategoriesList = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      commit("updateDates", payload);
    },
    updateEventTypes({ commit }, payload) {
      commit("updateEventTypes", payload);
    },
    updateEvents({ commit }, payload) {
      commit("updateEvents", payload);
    },
    updateProjects({ commit }, payload) {
      commit("updateProjects", payload);
    },
    updateEventCategories({ commit }, payload) {
      commit("updateEventCategories", payload);
    },
    updateFilteredEvents({ commit }, payload) {
      commit("updateFilteredEvents", payload);
    },
    async updateEventTypesList({ commit }, payload) {
      const url = "/rmra-event-types/";
      const options = {
        params: {
          projects: payload.join(","),
        },
      };
      const response = await axios.get(url, options);
      commit("updateEventTypesList", response.data.items);
    },
    async updateProjectsList({ commit }) {
      const url = "/rmra-projects/";
      const response = await axios.get(url);
      commit("updateProjectsList", response.data.items);
    },
    async updateEventCategoriesList({ commit }) {
      const url = "/rmra-event-categories/";
      const response = await axios.get(url);
      commit("updateEventCategoriesList", response.data.items);
    },
  },
};
