import axios from "@axios";

export default {
  namespaced: true,
  state: {
    sites: [],
    siteGroups: [],

    // VisitorsData :
    dates: [],
    containsText: "",

    // Forecast :
    forecastDates: [],
    gamAdUnits: [],
    gamPlacements: [],
    gamTargetingPreset: null,
    gamPositions: [],
    gamDeviceCategories: [],
    permutiveCohorts: [],

    // Marmiton :
    searchType: "",
    searchColumn: "",
  },
  getters: {
    getSites: (state) => state.sites,
    getSiteGroups: (state) => state.siteGroups,

    // VisitorsData :
    getContainsText: (state) => state.containsText,
    getDates: (state) => {
      // always return the dates ordered
      return state.dates[0] < state.dates[1]
        ? [state.dates[0], state.dates[1]]
        : [state.dates[1], state.dates[0]];
    },

    // Forecast :
    getForecastDates: (state) => {
      // always return the dates ordered
      return state.forecastDates[0] < state.forecastDates[1]
        ? [state.forecastDates[0], state.forecastDates[1]]
        : [state.forecastDates[1], state.forecastDates[0]];
    },
    getGamAdUnits: (state) => state.gamAdUnits,
    getGamPlacements: (state) => state.gamPlacements,
    getGamTargetingPreset: (state) => state.gamTargetingPreset,
    getGamPositions: (state) => state.gamPositions,
    getGamDeviceCategories: (state) => state.gamDeviceCategories,
    getPermutiveCohorts: (state) => state.permutiveCohorts,

    // Marmiton :
    getSearchType: (state) => state.searchType,
    getSearchColumn: (state) => state.searchColumn,
  },
  mutations: {
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },

    // VisitorsData :
    updateContainsText(state, payload) {
      state.containsText = payload;
    },
    updateDates(state, payload) {
      state.dates = payload;
    },

    // Forecast :
    updateForecastDates(state, payload) {
      state.forecastDates = payload;
    },
    updateGamAdUnits(state, payload) {
      state.gamAdUnits = payload;
    },
    updateGamPlacements(state, payload) {
      state.gamPlacements = payload;
    },
    updateGamTargetingPreset(state, payload) {
      state.gamTargetingPreset = payload;
    },
    updateGamPositions(state, payload) {
      state.gamPositions = payload;
    },
    updateGamDeviceCategories(state, payload) {
      state.gamDeviceCategories = payload;
    },
    updatePermutiveCohorts(state, payload) {
      state.permutiveCohorts = payload;
    },

    // Marmiton :
    updateSearchType(state, payload) {
      state.searchType = payload;
    },
    updateSearchColumn(state, payload) {
      state.searchColumn = payload;
    },
  },
  actions: {
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const site_ids = data
        .map((x) => x["site_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });

      commit("updateSites", site_ids);

      return site_ids;
    },

    // VisitorsData :
    updateDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateDates", payload);
      }
    },
    updateContainsText({ commit }, payload) {
      commit("updateContainsText", payload);
    },

    // Forecast :
    updateForecastDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateForecastDates", payload);
      }
    },
    updateGamAdUnits({ commit }, payload) {
      commit("updateGamAdUnits", payload);
    },
    updateGamPlacements({ commit }, payload) {
      commit("updateGamPlacements", payload);
    },
    updateGamTargetingPreset({ commit }, payload) {
      commit("updateGamTargetingPreset", payload);
    },
    updateGamPositions({ commit }, payload) {
      commit("updateGamPositions", payload);
    },
    updateGamDeviceCategories({ commit }, payload) {
      commit("updateGamDeviceCategories", payload);
    },
    updatePermutiveCohorts({ commit }, payload) {
      commit("updatePermutiveCohorts", payload);
    },

    // Marmiton :
    updateSearchType({ commit }, payload) {
      commit("updateSearchType", payload);
    },
    updateSearchColumn({ commit }, payload) {
      commit("updateSearchColumn", payload);
    },
  },
};
