import axios, { cacheTime } from "@axios";

export default {
  namespaced: true,
  state: {
    monthDate: "",
    dates: [],
    sites: [],
    siteGroups: [],
    partnersList: [],
    partnersViews: [],
    partnerViewGroups: [],
    groupCA: true,
  },
  getters: {
    getDates: (state) => {
      return state.dates;
    },
    getSites: (state) => {
      return state.sites;
    },
    getSiteGroups: (state) => {
      return state.siteGroups;
    },
    getPartnerViewGroups: (state) => {
      return state.partnerViewGroups;
    },
    getPartnerViews: (state) => {
      return state.partnersViews;
    },
    getPartnersList: (state) => {
      return state.partnersList;
    },
    getMonthDate: (state) => {
      return state.monthDate;
    },
    getGroupCA: (state) => {
      return state.groupCA;
    },
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updatePartnerViews(state, payload) {
      state.partnersViews = payload;
    },
    updatePartnerViewGroups(state, payload) {
      state.partnerViewGroups = payload;
    },
    updatePartnersList(state, payload) {
      state.partnersList = payload;
    },
    updateMonthDate(state, payload) {
      state.monthDate = payload;
    },
    updateGroupCA(state, payload) {
      state.groupCA = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      commit("updateDates", payload);
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);
      const { data } = await axios.get("/site-groups/sites", {
        params: { group_ids: payload.join(",") },
      });
      // get siteIds only and remove duplicates
      const siteIds = [...new Set(data.map((x) => x.site_id))].sort();
      commit("updateSites", siteIds);

      return siteIds;
    },
    async updatePartnerViewGroups({ commit }, payload) {
      commit("updatePartnerViewGroups", payload);
      if (!payload.length) {
        commit("updatePartnerViews", []);
        return;
      }
      const { data } = await axios.get("/partner-view-groups/partner-views", {
        params: { group_ids: payload.join(",") },
      });
      // get site_ids only and remove duplicates
      const partner_views_ids = data
        .map((x) => x["partner_view_id"])
        .sort()
        .filter((item, pos, ary) => {
          return !pos || item !== ary[pos - 1];
        });
      commit("updatePartnerViews", partner_views_ids);
      return partner_views_ids;
    },
    async getPartnersList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/");
      commit("updatePartnersList", items);

      return items;
    },
    async getDetailedRevenue({ commit }, payload) {
      const { data } = await axios.get(
        "/programmatic/detailed-revenue-monthly-report",
        {
          params: payload,
          cache: {
            maxAge: cacheTime,
          },
        }
      );

      return data;
    },
    updateMonthDate({ commit }, payload) {
      commit("updateMonthDate", payload);
    },
    updatePartnerViews({ commit }, payload) {
      commit("updatePartnerViews", payload);
    },
    updateGroupCA({ commit }, payload) {
      commit("updateGroupCA", payload);
    },
  },
};
