import { mdiAccount } from "@mdi/js";

const crmNav = [
  {
    title: "CRM",
    icon: mdiAccount,
    children: [
      {
        title: "GA / Site / Filtre",
        to: {
          name: "ga-sites-by-filter-crm",
        },
        resource: "crm",
        action: "manage",
      },
    ],
  },
];

export default crmNav;
