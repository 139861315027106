import { mdiCupcake } from "@mdi/js";

const marmiton = [
  {
    title: "Marmiton",
    icon: mdiCupcake,
    children: [
      {
        title: "Infos visiteurs",
        to: "marmiton-visitors",
        resource: "marmiton",
        action: "manage",
      },
    ],
  },
];

export default marmiton;
